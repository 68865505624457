import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Paragraph, Stack } from '@qasa/qds-ui'
import { FindHomeFiltersProvider } from '@qasa/app/src/features/find-home/contexts/find-home-filter-context'
import { FindHomeProvider } from '@qasa/app/src/features/find-home/contexts/find-home-context'
import { useQueryParamHomeFilters } from '@qasa/app/src/features/find-home/filters/use-query-param-home-filters.web'
import { HomeRentalTypeEnum } from '@qasa/graphql'

import { FindHomeMetaTags } from '../ui-page-modules/find-home/find-home-meta-tags'
import { QasaXBlocketLogo } from '../assets/logos/blocket/qasa-x-blocket'
import { FindHome } from '../ui-page-modules/find-home/find-home'
import { PageContainer } from '../ui-page-modules/find-home/page-layout'

export const EMBEDDED_FIND_HOME_PATH = '/embedded-find-home'

const PoweredByWrapper = styled(Stack)({
  height: 48,
  position: 'fixed',
  background: '#fff',
  borderTopLeftRadius: 4,
  right: 0,
  bottom: 0,
  padding: 8,
})

export function EmbeddedFindHome() {
  const { t } = useTranslation('embedded_find_home')
  const { filterValues, updateFilterValues, clearFilterValues } = useQueryParamHomeFilters()

  return (
    <FindHomeFiltersProvider
      filterValues={filterValues}
      updateFilterValues={updateFilterValues}
      clearFilterValues={clearFilterValues}
      rentalType={HomeRentalTypeEnum.long_term}
    >
      <FindHomeProvider rentalType={HomeRentalTypeEnum.long_term}>
        <PageContainer>
          <FindHomeMetaTags />
          <FindHome />
          <PoweredByWrapper direction="row" gap="3x" justifyContent={'flex-end'}>
            <Paragraph size="xs">{t('powered_by')}</Paragraph>
            <QasaXBlocketLogo height={16} />
          </PoweredByWrapper>
        </PageContainer>
      </FindHomeProvider>
    </FindHomeFiltersProvider>
  )
}
