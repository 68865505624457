import type { FunctionComponent, ReactFragment } from 'react'
import range from 'lodash/range'
import { Spacer, Paragraph } from '@qasa/qds-ui'
import { ampliExperiment } from '@qasa/ampli/p2'
import type { FindHomeNode } from '@qasa/app/src/types/find-home'
import { useFindHomeContext, useFindHomeFiltersContext } from '@qasa/app/src/features/find-home/contexts'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { HideInEmbeddedFindHomeWrapper } from '../../../ui-shared/hide-in-embedded-find-home-wrapper'
import { PAGE_SIZE } from '../find-home.utils'

import { HomeItem } from './home-item'
import { HomeListPagination } from './home-list-pagination'
import { SkeletonHome } from './skeleton-home'

const EmptyResult = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: 92,
  paddingBottom: 92,
  gap: 8,
})
const HomeItemGrid = styled.div<{ isMapOpen?: boolean }>(({ theme, isMapOpen }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
  rowGap: 32,
  columnGap: 24,

  ...(!isMapOpen && {
    [theme.mediaQueries['2xlUp']]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
    },
  }),
}))
type ResultsProps = {
  children: ReactFragment
  isLoading: boolean
  isEmpty: boolean
  isMapOpen?: boolean
  customEmptyCopy?: {
    title: string
    subtitle: string
  }
}
export function Results({ children, isLoading, isEmpty, isMapOpen, customEmptyCopy }: ResultsProps) {
  const { t } = useTranslation('find_home', { keyPrefix: 'list_of_homes' })

  if (isLoading) {
    return (
      <HomeItemGrid isMapOpen={isMapOpen}>
        {range(0, PAGE_SIZE).map((index) => (
          <SkeletonHome key={index} />
        ))}
      </HomeItemGrid>
    )
  } else if (isEmpty) {
    return (
      <EmptyResult>
        <Paragraph size={'lg'}>{customEmptyCopy ? customEmptyCopy.title : t('no_results')}</Paragraph>
        <Paragraph size={'sm'}>
          {customEmptyCopy ? customEmptyCopy.subtitle : t('try_adjust_filters')}
        </Paragraph>
      </EmptyResult>
    )
  } else {
    return <HomeItemGrid isMapOpen={isMapOpen}>{children}</HomeItemGrid>
  }
}

type ListOfHomesProps = {
  onHoveredHome: ({ home }: { home: FindHomeNode | null }) => void
  isMapOpen: boolean
  offeringInfoBox?: FunctionComponent
}
export function ListOfHomes({
  onHoveredHome,
  isMapOpen,
  offeringInfoBox: OfferingInfoBox,
}: ListOfHomesProps) {
  const { homes, isHomesLoading, totalPagesCount } = useFindHomeContext()
  const isEmpty = homes.length === 0
  const { filterValues } = useFindHomeFiltersContext()
  const { page, minNightlyCost, maxNightlyCost } = filterValues
  const isFirstPage = page === 1
  const indexToRenderOfferingInfoBox = Math.max(0, Math.min(23, homes.length - 1))
  const hasOpenListingsInNewTabTreatment =
    ampliExperiment.getVariant('open-listings-in-new-tab')?.value === 'treatment'

  const Offering = OfferingInfoBox ? (
    <HideInEmbeddedFindHomeWrapper key="offering-box">
      <OfferingInfoBox />
    </HideInEmbeddedFindHomeWrapper>
  ) : null

  return (
    <div>
      <Results isMapOpen={isMapOpen} isLoading={isHomesLoading} isEmpty={isEmpty}>
        {homes.map((home, index) => {
          const HomeListItem = (
            <HomeItem
              key={home.id}
              home={home}
              onHover={(isHovering) => onHoveredHome({ home: isHovering ? home : null })}
              checkInDate={filterValues.checkInDate}
              checkOutDate={filterValues.checkOutDate}
              isQasaShortcutHome={home.user.proAgent}
              hasFilteredOnPricePerNight={Boolean(minNightlyCost || maxNightlyCost)}
              shouldOpenInNewTab={hasOpenListingsInNewTabTreatment}
            />
          )

          const shouldRenderOfferingInfoBox =
            isFirstPage && index === indexToRenderOfferingInfoBox && Boolean(OfferingInfoBox)

          return shouldRenderOfferingInfoBox ? [HomeListItem, Offering] : HomeListItem
        })}
      </Results>
      <Spacer size={'8x'} />
      <HomeListPagination totalPages={totalPagesCount || 0} />
    </div>
  )
}
