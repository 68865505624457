import { useAppTranslation } from '@qasa/app/src/contexts/i18next'
import { useFindHomeFiltersContext } from '@qasa/app/src/features/find-home/contexts'

import { FilterCheckboxGroup } from '../components/filter-checkbox-group'

export function RulesFilterSection() {
  const { t } = useAppTranslation('filter_fields')
  const { filterValues, updateFilterValues } = useFindHomeFiltersContext()

  const rulesOptions = [
    {
      label: t('rules.pets_allowed'),
      value: 'pets',
    },
    { label: t('rules.smoking_allowed'), value: 'smoker' },
  ]
  return (
    <FilterCheckboxGroup
      label={t('rules.title', { ns: 'filter_sections' })}
      isLabelVisuallyHidden
      values={filterValues.rules}
      onChange={(newValue) => updateFilterValues({ rules: newValue })}
      options={rulesOptions}
    />
  )
}
