import { useFindHomeFiltersContext } from '@qasa/app/src/features/find-home/contexts'
import { HomeRentalTypeEnum, HomeSearchOrderEnum, HomeSearchOrderByEnum } from '@qasa/graphql'
import { useTranslation } from 'react-i18next'

const getSortingOptions = ({ rentalType }: { rentalType: HomeRentalTypeEnum }) => {
  const publishedDescending = {
    sort: {
      order: HomeSearchOrderEnum.DESCENDING,
      orderBy: HomeSearchOrderByEnum.PUBLISHED_AT,
    },
    name: 'published_descending',
  }
  const publishedAscending = {
    sort: {
      order: HomeSearchOrderEnum.ASCENDING,
      orderBy: HomeSearchOrderByEnum.PUBLISHED_AT,
    },
    name: 'published_ascending',
  }
  const squareMetersDescending = {
    sort: {
      order: HomeSearchOrderEnum.DESCENDING,
      orderBy: HomeSearchOrderByEnum.SQUARE_METERS,
    },
    name: 'square_meters_descending',
  }
  const squareMetersAscending = {
    sort: {
      order: HomeSearchOrderEnum.ASCENDING,
      orderBy: HomeSearchOrderByEnum.SQUARE_METERS,
    },
    name: 'square_meters_ascending',
  }

  if (rentalType === HomeRentalTypeEnum.vacation) {
    return [publishedDescending, publishedAscending, squareMetersDescending, squareMetersAscending]
  }
  return [
    publishedDescending,
    publishedAscending,
    {
      sort: {
        order: HomeSearchOrderEnum.ASCENDING,
        orderBy: HomeSearchOrderByEnum.MONTHLY_COST,
      },
      name: 'monthly_cost_ascending',
    },
    {
      sort: {
        order: HomeSearchOrderEnum.DESCENDING,
        orderBy: HomeSearchOrderByEnum.MONTHLY_COST,
      },
      name: 'monthly_cost_descending',
    },
    squareMetersDescending,
    squareMetersAscending,
    {
      sort: {
        order: HomeSearchOrderEnum.ASCENDING,
        orderBy: HomeSearchOrderByEnum.MOVE_IN,
      },
      name: 'move_in_ascending',
    },
    {
      sort: {
        order: HomeSearchOrderEnum.DESCENDING,
        orderBy: HomeSearchOrderByEnum.MOVE_IN,
      },
      name: 'move_in_descending',
    },
    {
      sort: {
        order: HomeSearchOrderEnum.ASCENDING,
        orderBy: HomeSearchOrderByEnum.MOVE_OUT,
      },
      name: 'move_out_ascending',
    },
    {
      sort: {
        order: HomeSearchOrderEnum.DESCENDING,
        orderBy: HomeSearchOrderByEnum.MOVE_OUT,
      },
      name: 'move_out_descending',
    },
  ]
}

export const useSorting = () => {
  const { filterValues, updateFilterValues, rentalType } = useFindHomeFiltersContext()
  const { order, orderBy } = filterValues
  const { t } = useTranslation('find_home', {
    keyPrefix: 'filters.sorting.options',
  })
  const availableSortingOptions = getSortingOptions({ rentalType })
  const currentValue =
    availableSortingOptions.find(({ sort }) => sort.order === order && sort.orderBy === orderBy)?.name ||
    'published_descending'

  const handleChange = (value: string) => {
    const newSortingValue = availableSortingOptions.find(({ name }) => name === value)?.sort
    updateFilterValues({ ...newSortingValue })
  }
  return {
    availableSortingOptions: availableSortingOptions.map(({ name }) => ({
      value: name,
      label: t(name),
    })),
    currentValue,
    handleChange,
  }
}
