import { Stack, Select } from '@qasa/qds-ui'
import { useState } from 'react'
import { styled } from '@qasa/ui/web'
import { useAppTranslation } from '@qasa/app/src/contexts/i18next'
import { FilterFieldWrapper } from '@qasa/app/src/features/find-home/filters/components/filter-field-wrapper'
import { DatepickerInput } from '@qasa/app/src/features/find-home/filters/components/date-picker-input.web'

import { useDatesFilterSection } from './dates-filter-section.shared'

// This prevents the Date picker from ending up below the visible viewport of
// the scrollable area. The pixel value is eye-balled, but it's better than nothing. -- Hugo 26/4/22
//
// Also the date pickers input has some spacing below it because the error text
// is always rendered even if it's empty. This temporarily handles that as well.
//
// TODO: Solve this some better way
const DatepickerGutter = styled('div')<{ isDatePickerOpen: boolean }>(({ isDatePickerOpen }) => ({
  height: isDatePickerOpen ? 308 : 16,
  transition: 'height 100ms ease-in-out',
}))

export function DatesFilterSection() {
  const { t } = useAppTranslation('filter_fields')
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const {
    earliestMoveInDate,
    handleEarliestMoveInChange,
    handleMinRentalLengthChange,
    minRentalLength,
    minRentalLengthOptions,
  } = useDatesFilterSection()
  return (
    <Stack>
      <FilterFieldWrapper label={t('earliest_move_in.label')}>
        <DatepickerInput
          label=""
          onOpenStateChange={setIsDatePickerOpen}
          value={earliestMoveInDate}
          onChange={handleEarliestMoveInChange}
        />
        <DatepickerGutter isDatePickerOpen={isDatePickerOpen} />
      </FilterFieldWrapper>
      <Select
        label={t('min_rental_length.label')}
        placeholder={t('common:show_all')}
        value={minRentalLength || ''}
        onChange={(e) => handleMinRentalLengthChange(e.target.value)}
      >
        {minRentalLengthOptions.map(({ value, label }) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Stack>
  )
}
