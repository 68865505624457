import { IconButton, MoreVerticalIcon, BookmarkIcon } from '@qasa/qds-ui'
import { styled } from '@qasa/ui/web'
import { DropdownMenu } from '@qasa/app/src/components/web'
import { useSearchHistoryContext } from '@qasa/app/src/features/find-home/search-history'

import { SearchHistorySummary } from '../search-history.parts'
import { SearchHistoryLink } from '../search-history-link'

import { DeleteSavedSearch } from './delete-saved-search'
import {
  useSavedSearchesListItem,
  type SavedSearchesListItemProps,
  MonitoringOption,
  DeleteOption,
} from './saved-searches-list-item.shared'

const SavedSearchItemWrapper = styled('li')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const DropdownTriggerButton = styled(IconButton)(({ theme }) => ({
  '&[data-state=open]': {
    backgroundColor: theme.colors.core.gray20,
  },
}))

export function SavedSearchesListItem({ savedSearch, onListItemPress }: SavedSearchesListItemProps) {
  const { rentalType } = useSearchHistoryContext()
  const {
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
    savedSearchFilters,
    searchSummary,
    updateSavedSearch,
    isMonitoring,
  } = useSavedSearchesListItem({ savedSearch, onListItemPress })

  const handleToggleMonitoringPress = (event: Event) => {
    event.preventDefault()
    updateSavedSearch()
  }

  const handleDeletePress = () => setIsDeleteDialogOpen(true)

  return (
    <SavedSearchItemWrapper>
      <SearchHistoryLink
        onPress={onListItemPress}
        filterValues={savedSearchFilters}
        path={rentalType === 'long_term' ? 'FindHome' : 'FindVacation'}
      >
        <BookmarkIcon size={16} style={{ flexShrink: 0 }} />
        <SearchHistorySummary searchSummary={searchSummary} />
      </SearchHistoryLink>
      <DropdownMenu>
        <DropdownMenu.Trigger>
          <DropdownTriggerButton icon={MoreVerticalIcon} label="View options" />
        </DropdownMenu.Trigger>
        {/* NOTE: needed this zIndex since the default one is lover than the dialog one */}
        <DropdownMenu.Content style={{ zIndex: 1500 }} className="saved-search-dropdown-menu">
          <DropdownMenu.Item onSelect={handleToggleMonitoringPress}>
            <MonitoringOption isMonitoring={isMonitoring} />
          </DropdownMenu.Item>
          <DropdownMenu.Item onSelect={handleDeletePress}>
            <DeleteOption />
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu>
      <DeleteSavedSearch
        isOpen={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
        savedSearch={savedSearch}
      />
    </SavedSearchItemWrapper>
  )
}
