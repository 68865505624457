import type { Reference } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { SavedSearchVisibilityEnum } from '@qasa/graphql'
import { useState } from 'react'
import { CREATE_SAVED_SEARCH } from '@qasa/app/src/features/find-home/search-history/saved-searches/saved-searches.gql'
import { useAppTranslation } from '@qasa/app/src/contexts/i18next'
import { useFindHomeContext, useFindHomeFiltersContext } from '@qasa/app/src/features/find-home/contexts'
import { useToastContext } from '@qasa/app/src/contexts/toasts'
import omit from 'lodash/omit'

import { filtersToHomeSearchParams } from '../find-home.utils'
import { getIsSearchFilterValuesEmpty } from '../search-history/search-history.utils'

export function useCreateSavedSearch() {
  const { areas, rentalType: searchRentalType } = useFindHomeContext()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { t } = useAppTranslation('saved_searches')
  const [isMonitoring, setIsMonitoring] = useState(true)

  const { filterValues } = useFindHomeFiltersContext()
  const isSearchFilterValuesEmpty = getIsSearchFilterValuesEmpty(filterValues)
  const { addToast } = useToastContext()

  const handleError = () => addToast({ message: t('commons:unexpected_error') })
  const [createSavedSearch, { loading: isLoadingCreateSavedSearch }] = useMutation(CREATE_SAVED_SEARCH, {
    onCompleted: (response) => {
      const errors = response.createSavedSearch?.errors
      if (errors) {
        handleError()
      } else {
        addToast({ message: t('create.success_toast_message'), timeout: 2000 })
        setIsMonitoring(true)
        setIsDialogOpen(false)
      }
    },
    onError: handleError,
    update: (cache, { data }) => {
      const newSavedSearch = data?.createSavedSearch?.savedSearch
      if (newSavedSearch) {
        cache.modify({
          fields: {
            savedSearch(existing: Reference[] = [], { toReference }) {
              return [toReference({ __typename: 'SavedSearch', id: newSavedSearch.id }), ...existing]
            },
          },
        })
      }
    },
  })
  const handleSubmit = () => {
    const searchParamFilters = filtersToHomeSearchParams({
      searchParams: filterValues,
      areas,
      rentalType: searchRentalType,
    })
    createSavedSearch({
      variables: {
        input: {
          // TODO: Remove title in BE
          title: '',
          monitoring: isMonitoring,
          visibility: SavedSearchVisibilityEnum.private,
          rentalType: searchRentalType,
          searchParams: omit(searchParamFilters, ['rentalType']),
        },
      },
    })
  }

  return {
    isDialogOpen,
    setIsDialogOpen,
    isMonitoring,
    setIsMonitoring,
    isLoadingCreateSavedSearch,
    handleSubmit,
    isSearchFilterValuesEmpty,
  }
}
