import { Label, SearchIcon, Spacer } from '@qasa/qds-ui'
import { styled } from '@qasa/ui/web'
import { Fragment, useEffect, useState, useRef } from 'react'
import { Paragraph } from '@qasa/ui'
import type { AutocompleteProps } from '@qasa/app/src/components/autocomplete/web'
import {
  AutocompleteBox,
  AutocompleteInput,
  AutocompleteListbox,
  useAutocomplete,
} from '@qasa/app/src/components/autocomplete/web'
import { useSearchHistoryContext } from '@qasa/app/src/features/find-home/search-history'
import { useFindHomeFiltersContext } from '@qasa/app/src/features/find-home/contexts'

import { VisuallyHidden } from '../../../ui-shared/_core/visually-hidden'
import { SearchHistoryDropdown } from '../search-history/search-history-dropdown'

import { ClearButton } from './components/area-search-dialog.parts'

const AutocompleteWrapper = styled('div')({
  position: 'relative',
})

const SearchIconWrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing['4x'],
}))

export function AutocompleteFindHome<TOption>({
  options,
  value,
  label,
  isLabelVisuallyHidden = false,
  placeholder,
  optionToLabel,
  optionToSublabel,
  onChange,
  maxOptions,
  inputValue,
  onInputValueChange,
  isLoading = false,
  errorMessage,
}: AutocompleteProps<TOption>) {
  const {
    isOpen: isAutocompleteOpen,
    selectedOptions,
    highlightedIndex,
    closeMenu,
    reset,
    getLabelProps,
    getComboboxProps,
    getSelectedItemProps,
    removeSelectedItem,
    getInputProps,
    getDropdownProps,
    getMenuProps,
    getItemProps,
  } = useAutocomplete<TOption>({
    options,
    value,
    onChange,
    inputValue,
    onInputValueChange,
    optionToLabel,
  })

  const { createPreviousSearch } = useSearchHistoryContext()
  const { filterValues } = useFindHomeFiltersContext()
  const timeoutRef = useRef<number | null>(null)

  const handleInputFocus = () => {
    if (timeoutRef.current !== null) {
      window.clearTimeout(timeoutRef.current)
    }
    if (!inputValue) {
      setIsSearchHistoryOpen(true)
    }
  }

  const handleInputBlur = () => {
    // Delay creating the previous search to check if the input gets focus again
    timeoutRef.current = window.setTimeout(() => {
      if (selectedOptions.length) {
        createPreviousSearch(filterValues)
      }
    }, 200)
  }

  const hasSelectedOptions = Boolean(selectedOptions.length)
  const hasSelectedOptionsOrInputValue = Boolean(selectedOptions.length) || Boolean(inputValue)

  const LabelWrapper = isLabelVisuallyHidden ? VisuallyHidden : Fragment
  const ListBoxWrapper = isAutocompleteOpen ? Fragment : VisuallyHidden

  const [isSearchHistoryOpen, setIsSearchHistoryOpen] = useState(false)

  const inputRef = useRef<HTMLDivElement>(null)
  const dropDownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement
      const isInsideClick =
        inputRef.current?.contains(targetElement) || dropDownRef.current?.contains(targetElement)

      // When opening the context menu, the click event is triggered on the document element
      // Probably because the click event does not have capture: true
      const isContextMenuTriggerClick = targetElement.isSameNode(document.documentElement)
      const isContextMenuClick = Boolean(targetElement.closest('.saved-search-dropdown-menu'))
      const isAlertDialogClick =
        Boolean(targetElement.closest('[role="dialog"]')) || Boolean(targetElement.dataset.state)

      const isAllowedClick = isContextMenuTriggerClick || isContextMenuClick || isAlertDialogClick

      if (isInsideClick || isAllowedClick) {
        return
      }

      setIsSearchHistoryOpen(false)
    }

    document.addEventListener('click', handleClickOutside, {
      capture: true,
    })
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  if (isAutocompleteOpen && isSearchHistoryOpen) {
    setIsSearchHistoryOpen(false)
  }

  return (
    <div ref={inputRef}>
      <AutocompleteWrapper>
        <LabelWrapper>
          <Label {...getLabelProps()}>{label}</Label>
          <Spacer size="2x" />
        </LabelWrapper>
        <AutocompleteBox hasError={Boolean(errorMessage)} isGray={true} {...getComboboxProps()}>
          {!hasSelectedOptions && (
            <SearchIconWrapper>
              <SearchIcon size={20} />
            </SearchIconWrapper>
          )}
          <AutocompleteInput
            onInputFocus={handleInputFocus}
            onInputBlur={handleInputBlur}
            {...{
              getInputProps,
              placeholder,
              optionToLabel,
              selectedOptions,
              maxOptions,
              getSelectedItemProps,
              getDropdownProps,
              isComboboxOpen: isAutocompleteOpen,
              removeSelectedItem,
            }}
          />
          {hasSelectedOptionsOrInputValue && (
            <ClearButton
              onClick={() => {
                reset()
                onInputValueChange('')
                setTimeout(() => {
                  inputRef.current?.querySelector('input')?.focus()
                }, 1)
              }}
            />
          )}
        </AutocompleteBox>
      </AutocompleteWrapper>
      <ListBoxWrapper>
        <AutocompleteListbox
          {...{
            inputValue,
            isLoading,
            isOpen: isAutocompleteOpen,
            optionToLabel,
            options,
            optionToSublabel,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            hasSavedSearches: true,
            closeMenu,
          }}
        />
      </ListBoxWrapper>
      {errorMessage && (
        <Paragraph size="sm" color="negative">
          {errorMessage}
        </Paragraph>
      )}
      {isSearchHistoryOpen && (
        <SearchHistoryDropdown ref={dropDownRef} onListItemPress={() => setIsSearchHistoryOpen(false)} />
      )}
    </div>
  )
}
