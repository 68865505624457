import { Redirect } from 'react-router-dom'

import { BRAND_TOGGLES } from '../config/toggles'
import { PROFILE_PATH } from '../legacy-pages/profile-page'
import { FIND_HOME_PATH } from '../legacy-pages/find-home-page'
import { CREATE_TENANT_LISTING_PATH } from '../legacy-pages/create-tenant-listing-page'
import { LocalisedContractSignSuccessWizard } from '../legacy-pages/contract-sign-success'
import { CreditVerification } from '../legacy-pages/credit-verification'
import { ShortcutOptIn } from '../legacy-pages/shortcut-opt-in'
import { currentBrand } from '../config'

import {
  FindHomePage,
  FindTenant,
  FindVacation,
  Login,
  AdminLogin,
  AdminLoginAsUser,
  Signup,
  PostSignup,
  ResetPassword,
  ResetPasswordRequest,
  ContractSendSuccessWizard,
  ProfilePage,
  AuthCallbackHandler,
  Home,
  CreateListing,
  EditListing,
  LandlordCalendar,
  ContractInvoices,
  Contracts,
  ExtendContract,
  TenantContractSignLanding,
  Messages,
  ListingDashboard,
  MyListings,
  AddBankAccount,
  Settings,
  IdVerificationModule,
  StudentVerification,
  EmploymentVerification,
  HomePublishSuccess,
  ProfilePublishSuccess,
  CreateTenantListingPage,
  ReservationSummary,
  ReservationSuccess,
  Logout,
  MessagesRedirect,
  Cookies,
  EmbeddedFindHome,
  ProPilotLanding,
  ProPilotContact,
  VerifyBankAccountPage,
  InstantMatch,
  Favorites,
  ContentfulPage,
  ClaimContractInvitationIntro,
  ClaimContractInvitation,
  ConfirmEmailPage,
  Help,
  Invite,
  OtpLogin,
  CreateListingLanding,
  InstantSign,
  LandlordContractSignLanding,
  LegalTermsAndConditions,
  HowItWorks,
  HomeqRedirectPage,
} from './lazy-pages'
import { getPath } from './get-path'

export type RoutePageOptions = {
  wizard?: boolean
  isNavBarHidden?: boolean
  isFooterHidden?: boolean
  isSchibstedSimplifiedLoginDisabled?: boolean
  schibstedFooterOffset?: 'hidden' | number
  schibstedFooterStyle?: 'default' | 'dark'
  footerSpacing?: 'default' | 'none'
}
export type RouteDeclaration = RoutePageOptions & {
  key: string
  path: string
  component: React.ElementType
  i18nTitle?: string
  isExact?: boolean
  isProtected?: boolean
  mobileOverride?: Partial<RoutePageOptions>
  isDisabled?: boolean
  isSunshineDisabled?: boolean
}

export const routes = [
  {
    key: 'contentful',
    path: '/c/:slug?',
    component: ContentfulPage,
    isNavBarHidden: true,
  },
  {
    key: 'proPilot',
    path: '/pro-pilot',
    i18nTitle: 'pro_pilot',
    component: ProPilotLanding,
  },
  {
    key: 'proPilotContact',
    path: '/pro-pilot-contact',
    wizard: true,
    i18nTitle: 'pro_pilot_contact',
    component: ProPilotContact,
  },
  {
    key: 'findHome',
    path: FIND_HOME_PATH,
    i18nTitle: 'find_home',
    component: FindHomePage,
  },
  {
    key: 'embeddedFindHome',
    path: '/embedded-find-home',
    i18nTitle: 'embedded-find-home',
    component: EmbeddedFindHome,
    isNavBarHidden: true,
    isFooterHidden: true,
    isSchibstedSimplifiedLoginDisabled: true,
  },
  {
    key: 'homeqRedirect',
    path: '/homeq-redirect',
    i18nTitle: 'homeq-redirect',
    component: HomeqRedirectPage,
    wizard: true,
    isNavBarHidden: true,
    isFooterHidden: true,
    isProtected: true,
  },
  {
    key: 'findTenant',
    path: '/find-tenant',
    i18nTitle: 'find_tenant',
    component: FindTenant,
  },
  {
    key: 'findVacation',
    path: '/find-vacation',
    i18nTitle: 'find_vacation',
    component: FindVacation,
  },
  {
    key: 'howItWorks',
    path: '/how-it-works',
    i18nTitle: 'how_it_works',
    component: HowItWorks,
    isNavBarHidden: true,
    schibstedFooterStyle: 'dark',
  },
  {
    key: 'login',
    path: '/login',
    component: Login,
    i18nTitle: 'login',
  },
  {
    key: 'otpLogin',
    path: '/otp-login',
    component: OtpLogin,
    i18nTitle: 'otp_login',
  },
  {
    key: 'signup',
    path: '/signup',
    component: Signup,
    i18nTitle: 'signup',
  },
  {
    key: 'postSignup',
    path: '/post-signup',
    component: PostSignup,
    i18nTitle: 'signup',
  },
  {
    key: 'resetPasswordRequest',
    path: '/reset-password-request',
    component: ResetPasswordRequest,
    i18nTitle: 'reset_password',
  },
  {
    key: 'resetPassword',
    path: '/reset-password/:token?',
    component: ResetPassword,
    i18nTitle: 'reset_password',
  },
  {
    key: 'claimContractInvitationIntro',
    path: '/claim-contract-invitation-intro/:claimKey',
    component: ClaimContractInvitationIntro,
    i18nTitle: 'claim_contract_invitation',
    wizard: true,
  },
  {
    key: 'claimContractInvitation',
    path: '/claim-contract-invitation/:claimKey',
    component: ClaimContractInvitation,
    i18nTitle: 'claim_contract_invitation',
    wizard: true,
    isProtected: true,
  },
  {
    key: 'adminLogin',
    path: '/admin-login',
    component: AdminLogin,
    i18nTitle: 'login',
  },
  {
    key: 'adminLoginAsUser',
    path: '/admin-login-as-user',
    component: AdminLoginAsUser,
    i18nTitle: 'login_as_user',
  },
  {
    key: 'leasesSignSuccess',
    path: '/leases/:contractId?/sign_success',
    component: LocalisedContractSignSuccessWizard,
    wizard: true,
    i18nTitle: 'lease_successfully_signed',
  },
  {
    key: 'leasesContractSendSuccess',
    path: '/leases/:contractId?/contract-send-success',
    component: ContractSendSuccessWizard,
    wizard: true,
    i18nTitle: 'lease_sent_for_signing',
  },
  {
    key: 'profile',
    path: PROFILE_PATH,
    component: ProfilePage,
  },
  { key: 'authCallback', path: '/auth_callback', component: AuthCallbackHandler },
  {
    key: 'instantSign',
    path: '/home/:homeId/instant-sign',
    component: InstantSign,
    i18nTitle: 'instant_sign',
    isProtected: true,
    wizard: true,
    isExact: false,
  },
  {
    key: 'home',
    path: '/home/:homeId',
    component: Home,
    i18nTitle: 'home',

    footerSpacing: 'none',
  },
  // NOTE: this is a home duplicate that is protected - it is used to force incognito mode users to login when
  // coming back from the verification that's done before publishing a home
  {
    key: 'homeFromVerify',
    path: '/home/:homeId/from-verify',
    component: Home,
    i18nTitle: 'home',
    footerSpacing: 'none',
    isProtected: true,
  },
  {
    key: 'cookies',
    path: `/cookies`,
    component: Cookies,
    i18nTitle: 'settings',
  },
  {
    key: 'createListing',
    path: '/create-listing',
    isExact: false,
    wizard: true,
    component: CreateListing,
    schibstedFooterOffset: 'hidden',
  },
  {
    key: 'createListingLanding',
    path: '/create-listing-landing',
    isExact: false,
    wizard: true,
    component: CreateListingLanding,
    schibstedFooterOffset: 'hidden',
  },
  {
    key: 'logout',
    path: '/logout',
    component: Logout,
  },
  {
    key: 'instantMatch',
    path: '/instant-match/:homeId',
    component: InstantMatch,
    wizard: true,
    isProtected: true,
  },
  {
    key: 'invite',
    path: '/invite',
    isExact: false,
    component: Invite,
    wizard: true,
    isSunshineDisabled: true,
  },
  {
    key: 'editListing',
    path: '/edit-listing/:homeId',
    isExact: false,
    wizard: true,
    component: EditListing,
    isProtected: true,
    mobileOverride: {
      schibstedFooterOffset: 72,
    },
  },
  {
    key: 'shortcutOptIn',
    path: '/shortcut-opt-in/:homeId',
    isExact: false,
    wizard: true,
    component: ShortcutOptIn,
    isProtected: true,
  },
  {
    key: 'landlordCalendar',
    path: '/calendar/:homeId',
    component: LandlordCalendar,
    isFooterHidden: true,
    wizard: true,
    isProtected: true,
  },
  {
    key: 'applications',
    path: '/applications',
    component: () => <Redirect to={getPath('messages')} />,
    i18nTitle: 'applications',
    isProtected: true,
  },
  {
    key: 'invoices',
    path: '/leases/:contractId/invoices',
    component: ContractInvoices,
    i18nTitle: 'invoices',
    isProtected: true,
  },
  {
    key: 'extendLease',
    path: '/leases/:contractId/extend',
    component: ExtendContract,
    i18nTitle: 'extend_contract',
    wizard: true,
    isProtected: true,
  },
  {
    key: 'leases',
    path: '/leases',
    component: Contracts,
    i18nTitle: 'contracts',
    isProtected: true,
  },
  {
    key: 'tenantSigningProcess',
    path: '/tenant-signing-process/:contractId',
    component: TenantContractSignLanding,
    wizard: true,
    isProtected: true,
  },
  {
    key: 'landlordSigningProcess',
    path: '/landlord-signing-process/:contractId',
    component: LandlordContractSignLanding,
    wizard: true,
    isProtected: true,
  },
  {
    key: 'homeqApplications',
    path: '/messages/homeq-applications',
    component: Messages,
    i18nTitle: 'messages',
    isFooterHidden: true,
    isExact: true,
    isProtected: true,
    schibstedFooterOffset: 'hidden',
    mobileOverride: {
      isNavBarHidden: true,
    },
  },
  {
    key: 'messagesRedirect',
    path: '/messages/:section(active|requests|closed|declined)/:conversationId?',
    isExact: true,
    component: MessagesRedirect,
    isProtected: true,
  },
  {
    key: 'messages',
    path: '/messages/:conversationId?',
    component: Messages,
    i18nTitle: 'messages',
    isFooterHidden: true,
    isExact: true,
    isProtected: true,
    schibstedFooterOffset: 'hidden',
    mobileOverride: {
      isNavBarHidden: true,
    },
  },
  {
    key: 'listingDashboard',
    path: '/my-listings/:homeId',
    component: ListingDashboard,
    i18nTitle: 'listing_dashboard',
    isProtected: true,
    isNavBarHidden: true,
  },
  {
    key: 'myListings',
    path: '/my-listings',
    component: MyListings,
    i18nTitle: 'my_listings',
    isProtected: true,
  },
  {
    key: 'addBankAccount',
    path: `/settings/bank_accounts/add`,
    component: AddBankAccount,
    i18nTitle: 'add_bank_account',
    wizard: true,
    isProtected: true,
  },
  {
    key: 'settings',
    path: `/settings`,
    component: Settings,
    i18nTitle: 'settings',
    isProtected: true,
  },
  {
    key: 'idVerification',
    path: `/id-verification`,
    component: IdVerificationModule,
    i18nTitle: 'id_verification',
    wizard: true,
    isProtected: true,
    isExact: false,
  },

  {
    key: 'bankAccountVerification',
    path: '/bank-account-verification/:token',
    component: VerifyBankAccountPage,
    i18nTitle: 'bank_account_verification',
    wizard: true,
    isProtected: true,
  },
  {
    key: 'incomeVerification',
    path: `/income-verification`,
    // NOTE: This page is now only a Dialog in the settings page, this redirect is just a fallback and can be removed in a few months - Sahin 04-07-2024
    component: () => <Redirect to={getPath('settings', { section: 'verifications' })} />,
    i18nTitle: 'income_verification',
  },
  {
    key: 'creditVerification',
    path: `/credit-verification`,
    component: CreditVerification,
    i18nTitle: 'credit_verification',
    wizard: true,
    isProtected: true,
    isDisabled: !BRAND_TOGGLES.isProPilotVerificationsEnabled,
  },
  {
    key: 'studentVerification',
    path: `/student-verification`,
    component: StudentVerification,
    i18nTitle: 'student_verification',
    wizard: true,
    isProtected: true,
    isDisabled: !BRAND_TOGGLES.isProPilotVerificationsEnabled,
  },
  {
    key: 'employmentVerification',
    path: `/employment-verification`,
    component: EmploymentVerification,
    i18nTitle: 'employment_verification',
    wizard: true,
    isProtected: true,
    isDisabled: !BRAND_TOGGLES.isProPilotVerificationsEnabled,
  },
  {
    key: 'homePublishedSuccess',
    path: '/home-published/success/:homeId',
    component: HomePublishSuccess,
    i18nTitle: 'home_publish_success',
    wizard: true,
    isProtected: true,
    schibstedFooterOffset: 'hidden',
  },
  {
    key: 'profilePublished',
    path: '/profile-published',
    component: ProfilePublishSuccess,
    i18nTitle: 'profile_publish_success',
    wizard: true,
    isProtected: true,
  },
  {
    key: 'createTenantListing',
    path: CREATE_TENANT_LISTING_PATH,
    isExact: false,
    wizard: true,
    i18nTitle: 'create_tenant_listing',
    component: CreateTenantListingPage,
    isProtected: true,
    mobileOverride: {
      schibstedFooterOffset: 72,
    },
  },
  {
    key: 'reserveHome',
    path: '/home/:homeId/reserve',
    component: ReservationSummary,
    i18nTitle: 'home_reservation',
    isFooterHidden: true,
  },
  {
    key: 'reserveHomeSuccess',
    path: '/home/:homeId/reservation-success',
    component: ReservationSuccess,
    i18nTitle: 'reservation_success',
    wizard: true,
    isProtected: true,
  },
  {
    key: 'favorites',
    path: '/favorites',
    component: Favorites,
    i18nTitle: 'favorites',
    isExact: true,
    isProtected: true,
  },
  {
    key: 'confirmEmail',
    path: '/confirm-email/:emailToken',
    component: ConfirmEmailPage,
    isFooterHidden: true,
    isProtected: true,
  },
  {
    key: 'help',
    path: '/help/:marketName?',
    component: Help,
    i18nTitle: 'help',
    footerSpacing: 'none',
  },
  {
    key: 'legal-terms-and-conditions',
    path: '/legal-terms-and-conditions/',
    component: LegalTermsAndConditions,
    i18nTitle: 'legal_terms_and_conditions',
    isDisabled: currentBrand !== 'dotcom',
  },
] as const satisfies readonly RouteDeclaration[]
