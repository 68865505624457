import { Spacer, Stack } from '@qasa/ui'
import { useAppTranslation } from '@qasa/app/src/contexts/i18next'
import { useSearchHistoryContext } from '@qasa/app/src/features/find-home/search-history'

import type { SearchHistoryProps } from '../search-history'
import { SearchHistoryHeading } from '../search-history.parts'

import { PreviousSearchesListItem } from './previous-searches-list-item'

export function PreviousSearches({ onListItemPress }: SearchHistoryProps) {
  const { t } = useAppTranslation('search_history')
  const { previousSearches } = useSearchHistoryContext()
  if (!previousSearches?.length) {
    return null
  }

  return (
    <Stack>
      <SearchHistoryHeading>{t('previous_searches.title')}</SearchHistoryHeading>
      <Spacer size="4x" />
      {previousSearches.map((previousSearch, index) => (
        <PreviousSearchesListItem
          previousSearch={previousSearch}
          onListItemPress={onListItemPress}
          key={index}
        />
      ))}
    </Stack>
  )
}
