import { HistoryIcon, Stack } from '@qasa/ui'
import type { HomeRentalTypeEnum } from '@qasa/graphql'
import type { PreviousSearchType } from '@qasa/app/src/features/find-home/search-history'
import { useSearchHistoryContext } from '@qasa/app/src/features/find-home/search-history'

import { SearchHistoryLink } from '../search-history-link'
import { SearchHistorySummary } from '../search-history.parts'
import type { SearchHistoryProps } from '../search-history'
import { useFormattedFilterValues } from '../../saved-searches/saved-searches.utils'

const findHomePaths: Record<HomeRentalTypeEnum, 'FindHome' | 'FindVacation'> = {
  long_term: 'FindHome',
  vacation: 'FindVacation',
}

type PreviousSearchesListItemProps = SearchHistoryProps & {
  previousSearch: PreviousSearchType
}
export function PreviousSearchesListItem({ onListItemPress, previousSearch }: PreviousSearchesListItemProps) {
  const { rentalType } = useSearchHistoryContext()

  const { filterValues } = previousSearch
  const searchSummary = useFormattedFilterValues({
    filterValues,
    rentalType,
  })

  return (
    <Stack>
      <SearchHistoryLink
        path={findHomePaths[rentalType]}
        filterValues={filterValues}
        onPress={onListItemPress}
      >
        <HistoryIcon size={16} style={{ flexShrink: 0 }} />
        <SearchHistorySummary searchSummary={searchSummary} />
      </SearchHistoryLink>
    </Stack>
  )
}
