import type { FindHomeFilterValues } from '@qasa/app/src/features/find-home/contexts'
import isEqual from 'lodash/isEqual'

import { DEFAULT_FILTER_VALUES } from '../default-filter-values'

type GetIsFilterActiveParams<TFilterKey extends keyof FindHomeFilterValues> = {
  filterKey: TFilterKey
  currentValue: FindHomeFilterValues[TFilterKey]
}

export const getIsFilterActive = <TFilterKey extends keyof FindHomeFilterValues>({
  filterKey,
  currentValue,
}: GetIsFilterActiveParams<TFilterKey>) => {
  return !isEqual(currentValue, DEFAULT_FILTER_VALUES[filterKey])
}

export const splitArrayAtIndex = <T>(array: T[], pivotIndex: number): [T[], T[]] => {
  const firstArray = array.slice(0, pivotIndex)
  const secondArray = array.slice(pivotIndex)
  return [firstArray, secondArray]
}
