import { Divider, Stack } from '@qasa/qds-ui'
import { HomeRentalTypeEnum } from '@qasa/graphql'
import { useSearchHistoryContext } from '@qasa/app/src/features/find-home/search-history'

import { SavedSearches } from './saved-searches/saved-searches'
import { RecommendedSearches } from './recommended-searches'
import { PreviousSearches } from './previous-searches'

export type SearchHistoryProps = {
  onListItemPress?: () => void
}
export function SearchHistory({ onListItemPress }: SearchHistoryProps) {
  const { savedSearches, previousSearches, rentalType } = useSearchHistoryContext()

  if (rentalType === HomeRentalTypeEnum.vacation) {
    return <SavedSearches onListItemPress={onListItemPress} />
  }
  return (
    <Stack gap="8x">
      <SavedSearches onListItemPress={onListItemPress} />
      {Boolean(savedSearches?.length) && <Divider />}
      <PreviousSearches onListItemPress={onListItemPress} />
      {Boolean(previousSearches?.length) && <Divider />}
      <RecommendedSearches onListItemPress={onListItemPress} />
    </Stack>
  )
}
