import { Checkbox, Label, Spacer } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { Fragment, type PropsWithChildren } from 'react'

import { VisuallyHidden } from '../../../../ui-shared/_core/visually-hidden'

type FilterCheckboxGroupProps = {
  label: string
  isLabelVisuallyHidden?: boolean
  options: { label: string; value: string }[]
  values: string[]
  onChange: (values: string[]) => void
}

function VisuallyHiddenOrFragment({
  isLabelVisuallyHidden,
  children,
}: PropsWithChildren<Pick<FilterCheckboxGroupProps, 'isLabelVisuallyHidden'>>) {
  return isLabelVisuallyHidden ? <VisuallyHidden>{children}</VisuallyHidden> : <Fragment>{children}</Fragment>
}

const parseCheckboxGroupValues = ({
  checkedValue,
  values,
}: Pick<FilterCheckboxGroupProps, 'values'> & { checkedValue: string }) => {
  if (values.includes(checkedValue)) {
    return values.filter((filterValue) => filterValue !== checkedValue)
  }
  return [checkedValue, ...values]
}

// Limit to 2 columns by setting the maximum min-width to 50%.
// Confusing I know but we want the inputs to be
// placed in a list in the poover and take up a maximum
// of 2 columns in the "more filters"-dialog -- Hugo 2022/05/19
const ITEM_MIN_WIDTH = 'max(50%, 180px)'

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  rowGap: theme.spacing['5x'],
  [theme.mediaQueries.mdUp]: {
    gridTemplateColumns: `repeat(auto-fill, minmax(${ITEM_MIN_WIDTH}, 1fr))`,
  },
}))

const GridItem = styled('div')(({ theme }) => ({
  [theme.mediaQueries.mdUp]: {
    paddingRight: theme.spacing['4x'],
  },
}))

export function FilterCheckboxGroup({
  options,
  label,
  onChange,
  values,
  isLabelVisuallyHidden,
}: FilterCheckboxGroupProps) {
  const ariaLabelId = ['checkbox-group', label].join('-')
  return (
    <div role="group" aria-labelledby={ariaLabelId}>
      <VisuallyHiddenOrFragment isLabelVisuallyHidden={isLabelVisuallyHidden}>
        <Label id={ariaLabelId} role="none">
          {label}
        </Label>
      </VisuallyHiddenOrFragment>
      <Spacer size="3x" />
      <Grid>
        {options.map(({ label, value }) => (
          <GridItem key={value}>
            <Checkbox
              label={label}
              isChecked={values.includes(value)}
              onCheckedChange={() => onChange(parseCheckboxGroupValues({ values, checkedValue: value }))}
            />
          </GridItem>
        ))}
      </Grid>
    </div>
  )
}
