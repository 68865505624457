import { useToggles } from '@qasa/app/src/configs/use-toggles'
import { useAppTranslation } from '@qasa/app/src/contexts/i18next'
import { useFindHomeFiltersContext } from '@qasa/app/src/features/find-home/contexts'
import { RadioGroup, Stack } from '@qasa/ui'

const ALL_HOMES_VALUE = 'allHomes'

export function ContractTypesFilterSection() {
  const { t } = useAppTranslation('filter_fields')
  const { hasFirstHandRentals } = useToggles()
  const {
    filterValues: { contractTypes },
    updateFilterValues,
  } = useFindHomeFiltersContext()

  const contractTypeOptions = [
    { value: ALL_HOMES_VALUE, label: t('contract_types.all_homes') },
    ...(hasFirstHandRentals ? [{ value: 'firstHand', label: t('contract_types.first_hand') }] : []),
    { value: 'studentHome', label: t('contract_types.student_home') },
    { value: 'seniorHome', label: t('contract_types.senior_home') },
    { value: 'corporateHome', label: t('contract_types.corporate_home') },
  ]

  const handleChange = (newValue: string) => {
    updateFilterValues({ contractTypes: [newValue] })
  }

  return (
    /**
     * TODO: When BE supports union filtering we will update this filter to use checkboxes. Since the current
     * implementation only allows a home to have one category, it makes sense to use radiobuttons for now.
     */
    <RadioGroup
      aria-label={t('filter_sections:contract_types.title')}
      // The query param supports an array of values, but right now we're only using one value
      // so we only use the first value in the array. See above comment for more details.
      value={contractTypes[0] ?? ALL_HOMES_VALUE}
      onValueChange={handleChange}
    >
      <Stack gap="2x">
        {contractTypeOptions.map(({ value, label }) => (
          <RadioGroup.Card key={value} value={value} label={label} />
        ))}
      </Stack>
    </RadioGroup>
  )
}
