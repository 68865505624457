import { Skeleton } from '@qasa/app/src/components/skeleton'
import styled from '@emotion/styled'
import { Spacer, Stack } from '@qasa/qds-ui'

const SkeletonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: 165,
  position: 'relative',
  overflow: 'hidden',
})
const SkeletonImageWrapper = styled.div({
  aspectRatio: '1/1',
  minWidth: '100%',
  maxWidth: '100%',
  maxHeight: 400,
  /* Fallback for browsers that don't support aspect-ratio, remove when not relevant anymore */
  minHeight: 200,
})
const SkeletonText = styled(Skeleton)<{ width: string; height?: number }>(
  ({ theme, width, height = '100%' }) => ({
    borderRadius: theme.radii.xl,
    width,
    height,
    backgroundClip: 'border-box',
    marginBottom: 0,
    '&::before': {
      borderRadius: theme.radii.xl,
    },
  }),
)

export function SkeletonHome() {
  return (
    <SkeletonWrapper>
      <SkeletonImageWrapper>
        <SkeletonText width="100%" />
      </SkeletonImageWrapper>
      <Stack>
        <Spacer size="4x" />
        <SkeletonText height={24} width="60%" />
        <Spacer size="2x" />
        <SkeletonText height={14} width="50%" />
        <Spacer size="1x" />
        <SkeletonText height={14} width="40%" />
        <Spacer size="2x" />
        <SkeletonText height={18} width="25%" />
      </Stack>
    </SkeletonWrapper>
  )
}
