import type { FindHomeFilterValues } from '@qasa/app/src/features/find-home/contexts'
import omit from 'lodash/omit'

const getSearchFilterValues = (filterValues: FindHomeFilterValues) => {
  return omit(filterValues, 'page', 'order', 'orderBy', 'uids', 'rentalType', 'safeRental')
}

export const getIsSearchFilterValuesEmpty = (filterValues: FindHomeFilterValues) => {
  const searchFilterValues = getSearchFilterValues(filterValues)

  return Object.values(searchFilterValues).every(
    (value) => value === null || (Array.isArray(value) && value.length === 0),
  )
}
