import { useLabelFormatters } from '../use-label-formatters'
import type { FilterSection } from '../filters.types'

import { RentFilterSection } from './long-term-sections/rent-filter-section'
import { HomeSizeFilterSection } from './long-term-sections/home-size-filter-section'
import { DatesFilterSection } from './long-term-sections/dates-filter-section'
import { HomeTypeFilterSection } from './long-term-sections/home-type-filter-section'
import { FurnishedFilterSection } from './long-term-sections/furnished-filter-section'
import { ContractTypesFilterSection } from './contract-types-filter-section'
import { AccessibilityFilterSection } from './accessibility-filter-section'
import { RulesFilterSection } from './rules-filter-section'

export const useLongTermFilterSections = (): FilterSection[] => {
  const { formatRent, formatHomeSize, formatDates, formatHomeTypes } = useLabelFormatters()
  return [
    {
      name: 'rent',
      Component: RentFilterSection,
      managedValues: ['minMonthlyCost', 'maxMonthlyCost'],
      valuesFormatter: formatRent,
    },
    {
      name: 'home_size',
      Component: HomeSizeFilterSection,
      managedValues: ['minRoomCount', 'maxRoomCount', 'minSquareMeters', 'maxSquareMeters', 'householdSize'],
      valuesFormatter: formatHomeSize,
    },
    {
      name: 'dates',
      Component: DatesFilterSection,
      managedValues: ['earliestMoveIn', 'minRentalLength'],
      valuesFormatter: formatDates,
    },
    {
      name: 'home_type',
      Component: HomeTypeFilterSection,
      managedValues: ['homeTypes', 'sharedHome'],
      valuesFormatter: formatHomeTypes,
    },
    { name: 'furnished', Component: FurnishedFilterSection, managedValues: ['furnished'] },
    { name: 'contract_types', Component: ContractTypesFilterSection, managedValues: ['contractTypes'] },
    { name: 'accessibility', Component: AccessibilityFilterSection, managedValues: ['wheelchairAccessible'] },
    { name: 'rules', Component: RulesFilterSection, managedValues: ['rules'] },
  ]
}
