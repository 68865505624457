import { useAppTranslation } from '@qasa/app/src/contexts/i18next'
import { useFindHomeContext, useFindHomeFiltersContext } from '@qasa/app/src/features/find-home/contexts'
import { getIsFilterActive } from '@qasa/app/src/features/find-home/filters/utils/filters.utils'
import { useSearchHistoryContext } from '@qasa/app/src/features/find-home/search-history'
import { HomeRentalTypeEnum } from '@qasa/graphql'
import { useState } from 'react'

import type { FilterSection } from './filters.types'

export type MoreFiltersDialogProps = {
  filterSections: FilterSection[]
}

export type MoreFiltersDialogTriggerProps = {
  activeFilterCount: number
}

export const useMoreFiltersDialog = ({ filterSections }: MoreFiltersDialogProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useAppTranslation('filter_sections')
  const { filterValues, clearFilterValues } = useFindHomeFiltersContext()
  const { homesResultCount, rentalType } = useFindHomeContext()
  const { createPreviousSearch } = useSearchHistoryContext()

  const allManagedValues = filterSections.map((section) => section.managedValues).flat()
  const activeFilterCount = allManagedValues.filter((filterKey) =>
    getIsFilterActive({ filterKey, currentValue: filterValues[filterKey] }),
  ).length
  const hasActiveFilters = activeFilterCount > 0

  const handleDialogChange = (isOpen: boolean) => {
    if (!isOpen && rentalType === HomeRentalTypeEnum.long_term) {
      createPreviousSearch(filterValues)
    }

    setIsOpen(isOpen)
  }
  const closeDialog = () => handleDialogChange(false)

  /* We still wanna show 0 so only check for undefined */
  const homesResultCountLabel =
    homesResultCount !== undefined
      ? t('show_results_with_count', { count: homesResultCount })
      : t('show_results')

  return {
    isOpen,
    activeFilterCount,
    hasActiveFilters,
    clearFilterValues: () => clearFilterValues(allManagedValues),
    closeDialog,
    handleDialogChange,
    homesResultCountLabel,
  }
}
