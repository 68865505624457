import { SortingSelect } from '@qasa/app/src/components/sorting-select'
import { useTranslation } from 'react-i18next'

import { useSorting } from './use-sorting'

export function Sorting() {
  const { availableSortingOptions, currentValue, handleChange } = useSorting()
  const { t } = useTranslation('find_home', {
    keyPrefix: 'filters.sorting',
  })
  return (
    <SortingSelect
      options={availableSortingOptions}
      value={currentValue}
      onChange={handleChange}
      ariaLabel={t('label')}
    />
  )
}
