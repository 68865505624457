import { graphql } from '@qasa/graphql'

export const HOME_SEARCH_COORDS = graphql(`
  query HomeSearchCoordsQuery(
    $market: MarketNameTypeEnum
    $searchParams: HomeSearchParamsInput
    $filterOnArea: Boolean
  ) {
    homeSearchCoords(market: $market, searchParams: $searchParams, filterOnArea: $filterOnArea) {
      filterHomesRaw
    }
  }
`)
