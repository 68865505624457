import { styled } from '@qasa/ui/web'
import { forwardRef, type Ref } from 'react'
import { getSearchQueryString } from '@qasa/app/src/utils/search'
import { getPath } from '@qasa/app/src/navigation'

import type { SearchHistoryLinkProps } from './search-history.types'

const Link = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingInline: theme.spacing['4x'],
  paddingBlock: theme.spacing['2x'],
  minHeight: theme.sizes['16x'],
  gap: theme.sizes['4x'],
  flex: 1,
  minWidth: 0,
  borderRadius: theme.radii.full,
  textDecoration: 'none',
  [theme.mediaQueries.mdUp]: {
    minHeight: theme.sizes['12x'],
    ':hover': {
      backgroundColor: theme.colors.core.offWhite,
    },
  },
}))

export const SearchHistoryLink = forwardRef(
  (props: SearchHistoryLinkProps, forwardedRef?: Ref<HTMLAnchorElement>) => {
    const { filterValues, path = 'FindHome', onPress, children } = props
    const basePath = getPath(path)
    const search = getSearchQueryString({ filterValues })
    return (
      <Link
        href={`${basePath}${search}`}
        ref={forwardedRef}
        onClick={() => {
          // Delay the onPress callback to allow the link to navigate first.
          // Else the navigation will be cancelled.
          setTimeout(() => {
            onPress?.()
          }, 1)
        }}
      >
        {children}
      </Link>
    )
  },
)
