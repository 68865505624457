import range from 'lodash/range'
import { useLocation, Link } from 'react-router-dom'
import { ArrowLeftIcon, ArrowRightIcon, Stack, useBreakpointValue } from '@qasa/qds-ui'
import isPropValid from '@emotion/is-prop-valid'
import type { FindHomeFilterValues } from '@qasa/app/src/features/find-home/contexts'
import { useFindHomeFiltersContext } from '@qasa/app/src/features/find-home/contexts'
import { getSearchQueryString } from '@qasa/app/src/utils/search'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { useEffectOnUpdate } from '../../../hooks/use-effect-on-update'

const CIRCLE_SIZE = 32
const ADJACENT_PAGES_VISIBLE = 3
const ADJACENT_PAGES_VISIBLE_MOBILE = 1

export const CircleLink = styled(Link, { shouldForwardProp: (prop) => isPropValid(prop) })<{
  isActive?: boolean
}>(({ theme, isActive }) => ({
  width: CIRCLE_SIZE,
  height: CIRCLE_SIZE,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.radii.full,
  ...theme.typography.body.md,
  fontWeight: 700,
  background: isActive ? theme.colors.bg.brandPrimary : 'inherit',
  color: isActive ? theme.colors.text.onBrandPrimary : 'inherit',
  ':hover': {
    background: isActive ? undefined : theme.colors.core.gray20,
  },
  /**
   * Make tap area larget without affecting actual DOM-size
   */
  position: 'relative',
  ':before': {
    content: '""',
    position: 'absolute',
    inset: -4,
  },
}))
const AdjacentPage = CircleLink

export const PageDots = styled.span(({ theme }) => ({
  width: CIRCLE_SIZE,
  textAlign: 'center',
  ...theme.typography.body.md,
  fontWeight: 700,
  userSelect: 'none',
}))

type PageNumberProps = {
  onClick?: () => void
  number: number
  isCurrent?: boolean
  to: string
}
export function PageNumber({ to, number, isCurrent = false, onClick }: PageNumberProps) {
  return (
    <CircleLink to={to} isActive={isCurrent} onClick={onClick}>
      {number}
    </CircleLink>
  )
}

type VisibleBlockProps = {
  totalPages: number
  currentPage: number
  adjacentPagesVisible: number
  pathname: string
  filterValues: FindHomeFilterValues
}
function VisibleBlock({
  currentPage,
  totalPages,
  adjacentPagesVisible,
  filterValues,
  pathname,
}: VisibleBlockProps) {
  const visiblePageNumbers = range(
    Math.max(2, currentPage - adjacentPagesVisible),
    Math.min(totalPages, currentPage + adjacentPagesVisible + 1),
  )
  return (
    <>
      {visiblePageNumbers!.map((pageNumber) => (
        <PageNumber
          to={getPageLink({ pathname, filterValues, pageNumber })}
          key={pageNumber}
          number={pageNumber}
          isCurrent={currentPage === pageNumber}
        />
      ))}
    </>
  )
}

type GetPageLinkParams = {
  pathname: string
  filterValues: FindHomeFilterValues
  pageNumber: number
}
const getPageLink = ({ pathname, filterValues, pageNumber }: GetPageLinkParams) => {
  return pathname + getSearchQueryString({ filterValues: { ...filterValues, page: pageNumber } })
}

type HomeListPaginationProps = {
  totalPages: number
}
export function HomeListPagination({ totalPages }: HomeListPaginationProps) {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const adjacentPagesVisible = isMobile ? ADJACENT_PAGES_VISIBLE_MOBILE : ADJACENT_PAGES_VISIBLE
  const { filterValues } = useFindHomeFiltersContext()
  const { t } = useTranslation('commons')
  const { pathname } = useLocation()
  const page = filterValues.page

  /**
   *  We trigger a scroll up on page change.
   *  Previously this was handles in `onPageClick` but that caused
   *  issues with blank pages in Safari on iOS -- Hugo 23/5/22
   */
  useEffectOnUpdate(() => {
    window.scrollTo({ top: 0 })
  }, [page])

  if (totalPages < 2) return null

  const isShowingFirstDots = totalPages > 2 * adjacentPagesVisible && page > adjacentPagesVisible + 2

  let FirstDots = null
  if (isShowingFirstDots) {
    if (page === 1 + (2 + adjacentPagesVisible)) {
      FirstDots = <PageNumber to={getPageLink({ pathname, filterValues, pageNumber: 2 })} number={2} />
    } else FirstDots = <PageDots>{'...'}</PageDots>
  }

  const isShowingSecondDots =
    totalPages > 2 * adjacentPagesVisible && page < totalPages - adjacentPagesVisible - 1
  let SecondDots = null
  if (isShowingSecondDots) {
    if (page === totalPages - (2 + adjacentPagesVisible)) {
      SecondDots = (
        <PageNumber
          to={getPageLink({ pathname, filterValues, pageNumber: totalPages - 1 })}
          number={totalPages - 1}
        />
      )
    } else SecondDots = <PageDots>{'...'}</PageDots>
  }

  const nextPage = Math.min(page + 1, totalPages)
  const nextPageLink = getPageLink({ pathname, filterValues, pageNumber: nextPage })
  const Increment = (
    <AdjacentPage aria-label={t('next')} to={nextPageLink}>
      <ArrowRightIcon size={16} />
    </AdjacentPage>
  )

  const previousPage = Math.max(1, page - 1)
  const previousPageLink = getPageLink({ pathname, filterValues, pageNumber: previousPage })
  const Decrement = (
    <AdjacentPage aria-label={t('back')} to={previousPageLink}>
      <ArrowLeftIcon size={16} />
    </AdjacentPage>
  )

  return (
    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'2x'}>
      {Decrement}
      <PageNumber
        to={getPageLink({ pathname, filterValues, pageNumber: 1 })}
        isCurrent={page === 1}
        number={1}
      />
      {FirstDots}
      <VisibleBlock
        pathname={pathname}
        filterValues={filterValues}
        currentPage={page}
        totalPages={totalPages}
        adjacentPagesVisible={adjacentPagesVisible}
      />
      {SecondDots}
      <PageNumber
        to={getPageLink({ pathname, filterValues, pageNumber: totalPages })}
        isCurrent={page === totalPages}
        number={totalPages}
      />
      {Increment}
    </Stack>
  )
}
