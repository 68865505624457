import differenceWith from 'lodash/differenceWith'
import type { SelectedArea } from '@qasa/app/src/types/find-home'

import type { useAreaSuggestions } from '../use-area-suggestions'

export const selectedAreaToLabel = (option: SelectedArea | null) => (option ? option.name : '')
export const selectedAreaToSublabel = (option: SelectedArea | null) => option?.city

type AreaSuggestions = ReturnType<typeof useAreaSuggestions>['suggestedAreas']

type GetUnselectedAreaOptionsParams = {
  areaSuggestions: AreaSuggestions
  selectedAreas: SelectedArea[]
}
export const getUnselectedAreaOptions = ({
  areaSuggestions,
  selectedAreas,
}: GetUnselectedAreaOptionsParams) => {
  return differenceWith(areaSuggestions, selectedAreas, (a, b) => {
    const aCity = a.city ?? ''
    const bCity = b.city ?? ''
    const aCountryCode = a.countryCode ?? ''
    const bCountryCode = b.countryCode ?? ''
    return a.name === b.name && aCity === bCity && aCountryCode === bCountryCode
  })
}
