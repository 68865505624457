import styled from '@emotion/styled'
import { Button, ListIcon, MapIcon, useBreakpointValue } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div<Pick<MapToggleButtonProps, 'isShowingMap'>>(({ isShowingMap, theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: isShowingMap ? 'fixed' : 'sticky',
  bottom: 20,
  touchAction: 'none',
  alignSelf: 'center',
  boxShadow: theme.shadows.md,
  borderRadius: theme.radii.full,
  [theme.mediaQueries.xlUp]: {
    position: 'sticky',
  },
}))

type MapToggleButtonProps = {
  isShowingMap: boolean
  onClick: () => void
}
export function MapToggle({ isShowingMap, onClick }: MapToggleButtonProps) {
  const { t } = useTranslation('find_home')
  const isXlOrAbove = useBreakpointValue({ base: false, xl: true })

  const icon = isShowingMap ? ListIcon : MapIcon

  const smallerScreenCopy = isShowingMap ? 'list' : 'map'
  const largerScreenCopy = isShowingMap ? 'hide_map' : 'show_map'
  const labelKey = isXlOrAbove ? largerScreenCopy : smallerScreenCopy
  return (
    <Wrapper isShowingMap={isShowingMap}>
      <Button iconLeft={icon} onClick={onClick}>
        {t(labelKey)}
      </Button>
    </Wrapper>
  )
}
