import { forwardRef } from 'react'
import { Stack } from '@qasa/qds-ui'
import { styled } from '@qasa/ui/web'
import { useSearchHistoryContext } from '@qasa/app/src/features/find-home/search-history'

import { SearchHistory } from './search-history'

const Dropdown = styled(Stack)<{ horizontalOffset: number }>(({ theme, horizontalOffset }) => ({
  position: 'absolute',
  right: horizontalOffset,
  left: horizontalOffset,
  paddingInline: theme.spacing['4x'],
  paddingBlock: theme.spacing['6x'],
  minHeight: theme.sizes['12x'],
  maxHeight: 'min(394px, 50vh)',
  marginTop: theme.spacing['2x'],
  background: theme.colors.bg.default,
  borderRadius: theme.radii.lg,
  border: '1px solid',
  borderColor: theme.colors.border.subtle,
  boxShadow: theme.shadows.lg,
  overflowY: 'auto',
  zIndex: theme.zIndices.dropdown,
  gap: theme.spacing['8x'],
}))

type SearchHistoryDropdownProps = {
  onListItemPress?: () => void
  horizontalOffset?: number
}
export const SearchHistoryDropdown = forwardRef<HTMLDivElement, SearchHistoryDropdownProps>(
  ({ onListItemPress, horizontalOffset = 0 }, forwardedRef) => {
    const { savedSearches, previousSearches } = useSearchHistoryContext()

    if (!savedSearches?.length && !previousSearches?.length) {
      return null
    }

    return (
      <Dropdown ref={forwardedRef} horizontalOffset={horizontalOffset}>
        <SearchHistory onListItemPress={onListItemPress} />
      </Dropdown>
    )
  },
)
